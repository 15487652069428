import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import './GuardingPost.css';

// Import images for each service
import securityGuardsImage from '../../Assets/images/securityguard.png';
import mobileResponseImage from '../../Assets/images/RSSCar.png';
import executiveSecurityImage from '../../Assets/images/SecurityGuardWorkplace.png';
import remoteSiteImage from '../../Assets/images/cctvs.jpg';
import eventSecurityImage from '../../Assets/images/securityPersonell.png';

const PhysicalSecurity = () => {
  const [expandedService, setExpandedService] = useState('');

  // Function to toggle service details
  const toggleService = (service) => {
    setExpandedService(expandedService === service ? '' : service);
  };

  return (
    <div className="physicalSecuritySection1">
      <div className="topCardSection1">
      <h2 className="physicalSecurityTitle1">
      PHYSICAL <span className="physical-highlighted-text1">SECURITY</span>
          </h2>
      <div className="PhysicalSecurityDescription1">
        <p>
          We recognize the challenges of providing a secure working environment—particularly when a client’s work sites are in remote locations.
          Our solutions are based on a comprehensive analysis of the security requirement and may integrate manned guarding, canine security, and
          technological security elements. This analysis impacts the design and implementation of appropriate and bespoke security solutions. Such
          solutions are routinely reviewed to ensure continual improvement that is in line with an often rapidly changing security environment. We
          provide security solutions to clients across the residential, commercial, agricultural, industrial, and NGO/diplomatic sectors. Our layered
          security plans encompass fixed and roving posts, checkpoints, access control, mobile services, and technical security systems. We analyze
          client vulnerabilities and follow up with recommendations ranging from installing security equipment and deploying security personnel to the
          development of information security programs.
        </p>
      </div>
      </div>
 

      {/* Security Guards */}
      <div className="physicalService1">
   
        <div className="serviceHeader1" onClick={() => toggleService('Security Guards')}>
          <h3 className="serviceTitle1">
               Security <span className="physical-highlighted-Servicetext1">Guards</span>
          </h3>
          <button className="expandButton1">
            {expandedService === 'Security Guards' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Security Guards' && (
          <div className="serviceDetails1 active">
            <img src={securityGuardsImage} alt="Security Guards" className="serviceImage1" />
            <p>
              Each manned security guard is trained to the highest standard, to ensure maximum coverage when it comes to safety. No matter what type of
              industry you work in, we can deploy manned security guards for any occasion and location in Kenya. Our highly-trained security guards have
              extensive knowledge and experience in securing access points across all industries.
              <br />
              <br />
              Whether you need gatehouse security to control access points to construction and industrial sites or you require static security guards for corporate premises to provide a professional image, our security guards are equipped to tailor their expertise to a whole host of facilities.
            </p>
          </div>
        )}
      </div>

      {/* Mobile Response */}
      <div className="physicalService1">
        <div className="serviceHeader1" onClick={() => toggleService('Mobile Response')}>
          <h3 className="serviceTitle1">
               Mobile <span className="physical-highlighted-Servicetext1">Response</span>
          </h3>
          <button className="expandButton1">
            {expandedService === 'Mobile Response' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Mobile Response' && (
          <div className="serviceDetails1 active">
            <img src={mobileResponseImage} alt="Mobile Response" className="serviceImage1" />
            <p>
              Our qualified, trained and experienced mobile response teams have extensive local knowledge and enable our clients to move safely and
              securely in high-risk, uncertain, and hostile environments. Our vehicles and crews are configured to meet client requirements within the
              context of the operating environment.
              <br />
              <br />
              Our fleet of B6 armored and soft-skin vehicles is equipped with electronic countermeasures (ECM) and radio and satellite communications systems allowing our operations centers to track our clients’ movements in real-time. We are able to deploy different security vehicles depending on the latest threat, intelligence picture, and specific requirements of our clients, the task, and the operating environment.
              <h4>Our mobile response services include:</h4>
              <ul>
                <li><FaCheckCircle className="check-icon" /> Escort services and convoy protection</li>
                <li><FaCheckCircle className="check-icon" /> Executive protection</li>
                <li><FaCheckCircle className="check-icon" /> Alarm response</li>
                <li><FaCheckCircle className="check-icon" /> Firefighting and rescue</li>
                <li><FaCheckCircle className="check-icon" /> Emergency medical response</li>
              </ul>
            </p>
          </div>
        )}
      </div>

      {/* VIP & Executive Security Escort */}
      <div className="physicalService1">
        <div className="serviceHeader1" onClick={() => toggleService('VIP & Executive Security Escort')}>
          <h3 className="serviceTitle1">
          VIP & Executive  <span className="physical-highlighted-Servicetext1">Security Escort</span>
          </h3>
          <button className="expandButton1">
            {expandedService === 'VIP & Executive Security Escort' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'VIP & Executive Security Escort' && (
          <div className="serviceDetails1 active">
            <img src={executiveSecurityImage} alt="VIP & Executive Security Escort" className="serviceImage1" />
            <p>
              As a personal protection company, REPAY Security Services Ltd will go beyond the call of duty and meet all of your demands. With the right
              staff in place, careful planning, and an unrivaled commitment to protecting your most valuable assets - whether it's your children, partner
              or Home - our security services make your life as easy as possible.
              <br />
              <br />
              Give your most valuable assets the protection they deserve with personal security that incorporates the greatest levels of respect, trust, and professionalism to create a secure, safe, and discreet service - without compromise.
              Protection for High Profile Individuals including celebrities and dignitaries to heads of corporations, we deliver unrivaled levels of protection both domestically and internationally.
              Our full range of personal protection services includes:
              <ul>
                <li><FaCheckCircle className="check-icon" /> Celebrity Security</li>
                <li><FaCheckCircle className="check-icon" /> Close Protection / Bodyguards</li>
                <li><FaCheckCircle className="check-icon" /> Security Drivers</li>
                <li><FaCheckCircle className="check-icon" /> Valuable Asset Security Escorts</li>
                <li><FaCheckCircle className="check-icon" /> VIP Security</li>
              </ul>
              <br />
              Our close-knit team of fully licensed operatives will respond quickly, calmly, and effectively to emergency situations, while also demonstrating the skills and experience to identify and eliminate risk before it occurs.
              From the highly visible to the highly discreet, our expertly trained and fully licensed security operatives pay close attention to every security detail to deliver personal protection that is second to none.
            </p>
          </div>
        )}
      </div>

      {/* Remote Site Security */}
      <div className="physicalService1">
        <div className="serviceHeader1" onClick={() => toggleService('Remote Site Security')}>
          <h3 className="serviceTitle1">
          Remote Site  <span className="physical-highlighted-Servicetext1">Security</span>
          </h3>
          <button className="expandButton1">
            {expandedService === 'Remote Site Security' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Remote Site Security' && (
          <div className="serviceDetails1 active">
            <img src={remoteSiteImage} alt="Remote Site Security" className="serviceImage1" />
            <p>
              We recognize the challenges of providing security changes from country to country, region to region, and location to location. Our solutions
              are based on a comprehensive analysis of the security requirement and may integrate static, mobile, and technological security elements. Our
              layered security plans are based on the analysis of client vulnerabilities and include recommendations on security Equipment and security
              personnel and, in some cases, even information security programs.
            </p>
          </div>
        )}
      </div>

      {/* Event Security */}
      <div className="physicalService1">
        <div className="serviceHeader1" onClick={() => toggleService('Event Security')}>
          <h3 className="serviceTitle1">
          Event  <span className="physical-highlighted-Servicetext1">Security</span>
          </h3>
          <button className="expandButton1">
            {expandedService === 'Event Security' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Event Security' && (
          <div className="serviceDetails1 active">
            <img src={eventSecurityImage} alt="Event Security" className="serviceImage1" />
            <p>
              Events must be effectively supervised to ensure a smooth flow of activities while offering an enjoyable experience to participants, which is
              why preventing risks and managing crowds are at the core of event security. Our approach is focused on customer service and upholding the
              highest professional standards. This means our highly trained, versatile, and adaptive event security professionals are able to face a
              variety of situations and behaviors. They abide by a client’s requirement as well as site and safety standards at all times.
              <br />
              <br />
              <h4>Our event security officers can manage a variety of responsibilities during events:</h4>
              <ul>
                <li><FaCheckCircle className="check-icon" /> Executive services</li>
                <li><FaCheckCircle className="check-icon" /> Access control</li>
                <li><FaCheckCircle className="check-icon" /> Mobile patrols</li>
                <li><FaCheckCircle className="check-icon" /> Metal detectors and guest security checks</li>
                <li><FaCheckCircle className="check-icon" /> Remote monitoring and management of materials and equipment</li>
              </ul>
            </p>
          </div>
        )}
      </div>

      {/* Add more guarding services here */}

    </div>
  );
};

export default PhysicalSecurity;
