import React, { useState } from 'react';
import './AlarmMonitoring.css'; // Assuming you have a CSS file for Alarm Monitoring

// Import images for each service
import automaticAlarmsImage from '../../Assets/images/AutomaticAlarm.png';
import manualAlarmsImage from '../../Assets/images/ManualAlarm (2).png';
import fireAlarmSystemsImage from '../../Assets/images/FireAlarm.png';
import smokeScreenSystemsImage from '../../Assets/images/smoke-alarm.png';
import intruderAlarmSystemsImage from '../../Assets/images/intruderAlarms.png';

const AlarmMonitoring = () => {
  const [expandedService, setExpandedService] = useState('');

  // Function to toggle service details
  const toggleService = (service) => {
    setExpandedService(expandedService === service ? '' : service);
  };

  return (
    <div className="alarmMonitoringSection">
      <h2 className="alarmMonitoringTitle">
        Alarm Monitoring <span className="alarm-highlighted-text">Response</span>
      </h2>
      <div className="alarmMonitoringDescription">
        <p>
          At REPAY SECURITY SERVICES LTD, we believe that alarm monitoring is essential for proactive security. Our advanced monitoring systems ensure rapid response to any potential threats, providing peace of mind for our clients.
        </p>
      </div>

      {/* Automatic Alarms */}
      <div className="alarmService">
        <div className="serviceHeader" onClick={() => toggleService('Automatic Alarms')}>
          <h3 className="serviceTitle">Automatic <span className="alarm-highlighted-Servicetext">Alarms</span></h3>
          <button className="expandButton">
            {expandedService === 'Automatic Alarms' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Automatic Alarms' && (
          <div className="serviceDetails active">
            <img src={automaticAlarmsImage} alt="Automatic Alarms" className="serviceImage" />
            <p>
              An automatic alarm system provides 24-hour coverage, triggering a rapid response from our command center upon activation. Features include control panels, exterior sirens, backup batteries, and more.
            </p>
          </div>
        )}
      </div>

      {/* Manual Alarms */}
      <div className="alarmService">
        <div className="serviceHeader" onClick={() => toggleService('Manual Alarms')}>
          <h3 className="serviceTitle">Manual <span className="alarm-highlighted-Servicetext">Alarms</span></h3>
          <button className="expandButton">
            {expandedService === 'Manual Alarms' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Manual Alarms' && (
          <div className="serviceDetails active">
            <img src={manualAlarmsImage} alt="Manual Alarms" className="serviceImage" />
            <p>
              A manual alarm system offers simplicity and effectiveness. With the push of a button, our command center is alerted, and a mobile response team is dispatched promptly to your location.
            </p>
          </div>
        )}
      </div>

      {/* Fire Alarm Systems */}
      <div className="alarmService">
        <div className="serviceHeader" onClick={() => toggleService('Fire Alarm Systems')}>
          <h3 className="serviceTitle">Fire <span className="alarm-highlighted-Servicetext">Alarm Systems</span></h3>
          <button className="expandButton">
            {expandedService === 'Fire Alarm Systems' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Fire Alarm Systems' && (
          <div className="serviceDetails active">
            <img src={fireAlarmSystemsImage} alt="Fire Alarm Systems" className="serviceImage" />
            <p>
              Our fire alarm systems offer accurate and quick detection of fires, safeguarding various types of facilities. Features include automatic sensors, break glass switches, and integrated sprinkler systems.
            </p>
          </div>
        )}
      </div>

      {/* Smoke Screen Systems */}
      <div className="alarmService">
        <div className="serviceHeader" onClick={() => toggleService('Smoke Screen Systems')}>
          <h3 className="serviceTitle">Smoke <span className="alarm-highlighted-Servicetext">Screen Systems</span></h3>
          <button className="expandButton">
            {expandedService === 'Smoke Screen Systems' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Smoke Screen Systems' && (
          <div className="serviceDetails active">
            <img src={smokeScreenSystemsImage} alt="Smoke Screen Systems" className="serviceImage" />
            <p>
              Smoke screen security systems deter intruders by releasing thick smoke, protecting valuables and preventing burglary attempts. This highly effective solution is suitable for residential and commercial properties.
            </p>
          </div>
        )}
      </div>

      {/* Intruder Alarm Systems */}
      <div className="alarmService">
        <div className="serviceHeader" onClick={() => toggleService('Intruder Alarm Systems')}>
          <h3 className="serviceTitle">Intruder <span className="alarm-highlighted-Servicetext">Alarm Systems</span></h3>
          <button className="expandButton">
            {expandedService === 'Intruder Alarm Systems' ? '-' : '+'}
          </button>
        </div>
        {expandedService === 'Intruder Alarm Systems' && (
          <div className="serviceDetails active">
            <img src={intruderAlarmSystemsImage} alt="Intruder Alarm Systems" className="serviceImage" />
            <p>
              Our intruder alarm systems detect unauthorized entry, providing early warnings and triggering immediate responses. Features include motion sensors, door contacts, and 24/7 monitoring.
            </p>
          </div>
        )}
      </div>

      {/* Add more alarm services here */}

    </div>
  );
};

export default AlarmMonitoring;
